import {
	array,
	boolean,
	check,
	email,
	instance,
	integer,
	literal,
	maxLength,
	minLength,
	nullish,
	number,
	object,
	pipe,
	string,
	union,
} from 'valibot';
import { isMobilePhone, isPostalCode } from '../formUtils';

const lead = object({
	id: nullish(union([literal(''), pipe(string(), maxLength(36))])),
	firstName: nullish(pipe(string(), maxLength(255))),
	lastName: string(minLength(1, 'Last Name is required.'), maxLength(255)),
	phone: nullish(
		union([
			pipe(
				string(),
				maxLength(40),
				check(isMobilePhone, 'Phone number appears to be invalid.'),
			),
			literal(''),
		]),
	),
	countryCode: nullish(pipe(string(), maxLength(2))),
	postalCode: nullish(
		union([
			pipe(
				string(),
				maxLength(20),
				check(isPostalCode, 'Postal code appears to be invalid.'),
			),
			literal(''),
		]),
	),
	country: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	email: pipe(
		string(),
		maxLength(80),
		email('Valid email address is required.'),
	),
	windowCount: nullish(pipe(number(), integer())),
	url: nullish(union([pipe(string(), maxLength(10000)), literal('')])),
	notes: nullish(union([pipe(string(), maxLength(2000)), literal('')])),
	formName: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	gclid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	gbraid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	wbraid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	rdtCid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	rdtUuid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	qclid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	fbp: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	fbclid: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	piwikproVisitorId: nullish(
		union([pipe(string(), maxLength(255)), literal('')]),
	),
	isResidential: nullish(boolean()),
	isCommercial: nullish(boolean()),
	smsOptOut: nullish(boolean()),
	supportOrderNumber: nullish(
		union([pipe(string(), maxLength(255)), literal('')]),
	),
	supportIssue: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	company: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	focusEnergy: nullish(boolean()),
	focusKit: nullish(boolean()),
	focusPrivacy: nullish(boolean()),
	focusSleep: nullish(boolean()),
	focusSound: nullish(boolean()),
	focusSpecialty: nullish(boolean()),
	focusTemperature: nullish(boolean()),
	focusThermal: nullish(boolean()),
	focusUV: nullish(boolean()),
	focusOther: nullish(boolean()),
	supportNotes: nullish(union([pipe(string(), maxLength(2000)), literal('')])),
	stateIncorporated: nullish(
		union([pipe(string(), maxLength(80)), literal('')]),
	),
	website: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	isContractor: nullish(boolean()),
	contractorTermsAccepted: nullish(boolean()),
	city: nullish(union([pipe(string(), maxLength(40)), literal('')])),
	street: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	state: nullish(union([pipe(string(), maxLength(80)), literal('')])),
	userAgent: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	inquiryType: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	images: nullish(array(nullish(instance(File)))),
	type: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	salesforce: nullish(boolean()),
	experiment: nullish(union([pipe(string(), maxLength(255)), literal('')])),
	otp: nullish(
		union([pipe(string(), minLength(6), maxLength(255)), literal('')]),
	),
});

export default lead;
